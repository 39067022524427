import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, Button, IconButton, DialogTitle, DialogContent } from '@mui/material';

// components

// import { getAllProject } from 'src/server/api/project';

import { useParams } from 'react-router';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import {
  createWalletAddress,
  createWalletAddressAdmin,
  updateWalletAddressDetails,
  updateWalletAddressDetailsAdmin,
} from 'src/server/api/walletAddress';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

WalletAddressDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenWalletAddressId: PropTypes.func,
  isUpdate: PropTypes.any,
  memberId: PropTypes.any,
};

export default function WalletAddressDialog({
  open,
  setOpen,
  isUpdate,
  setOpenWalletAddressId,
  memberId,
}) {
  const theme = useTheme();
  const params = useParams();
  const role = useGetRoles()?.role;
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // console.log(isUpdate);
  const handleClose = () => {
    setOpen(false);
    setOpenWalletAddressId({});
  };
  const dispatch = useDispatch();
  // const router = useRouter();

  // const { enqueueSnackbar } = useSnackbar();
  const { loading } = useSelector((state) => ({
    loading: state.walletAddress.loading,
    error: state.walletAddress.error,
  }));

  const SupportListSchema = Yup.object().shape({
    address: Yup.string(),
  });

  const defaultValues = useMemo(() => {
    if (isUpdate?._id) {
      return {
        title: isUpdate?.title || '',
        wallet_id: isUpdate?.wallet_id || '',
      };
    }

    return {
      title: '',
      wallet_id: '',
    };
  }, [isUpdate?._id, isUpdate?.title, isUpdate?.wallet_id]);

  const methods = useForm({
    resolver: yupResolver(SupportListSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (role !== 'admin') {
      const CreateCredentials = {
        state: {
          member: memberId,
          title: data?.title,
          wallet_id: data?.wallet_id,
        },
        dispatch,
        handleClose,
        handleClear,
      };
      const UpdateCredentials = {
        state: {
          member: memberId,
          title: data?.title,
          wallet_id: data?.wallet_id,
        },
        walletId: isUpdate?._id,

        dispatch,
        handleClose,
        handleClear,
      };
      if (isUpdate?._id) {
        dispatch(updateWalletAddressDetails(UpdateCredentials));
      } else {
        dispatch(createWalletAddress(CreateCredentials));
      }
    } else {
      const CreateCredentials = {
        state: {
          member: memberId,
          title: data?.title,
          wallet_id: data?.wallet_id,
        },
        id: params?.id,
        dispatch,
        handleClose,
        handleClear,
      };
      const UpdateCredentials = {
        state: {
          member: memberId,
          title: data?.title,
          wallet_id: data?.wallet_id,
        },
        walletId: isUpdate?._id,
        id: params?.id,

        dispatch,
        handleClose,
        handleClear,
      };
      if (isUpdate?._id) {
        dispatch(updateWalletAddressDetailsAdmin(UpdateCredentials));
      } else {
        dispatch(createWalletAddressAdmin(CreateCredentials));
      }
    }
  });

  // if(role=== 'admin'){

  //   const onSubmit = handleSubmit(async (data) => {
  //     const CreateCredentials = {
  //       state: {
  //         address: data?.address,
  //       },
  //       dispatch,
  //       handleClose,
  //       handleClear,
  //     };
  //     const UpdateCredentials = {
  //       state: {
  //         address: data?.address,
  //       },
  //       walletId: isUpdate?._id,

  //       dispatch,
  //       handleClose,
  //       handleClear,
  //     };
  //     if (isUpdate?._id) {
  //       dispatch(updateWalletAddressDetails(UpdateCredentials));
  //     } else {
  //       dispatch(createWalletAddress(CreateCredentials));
  //     }
  //   });
  // }
  // console.log(isUpdate);

  const renderForm = (
    <Stack spacing={3} sx={{ p: 1, px: 0 }}>
      <RHFTextField name="title" label="Title" type="text" required placeholder="Title" />
      <RHFTextField
        name="wallet_id"
        label="Wallet ID"
        type="text"
        required
        placeholder="Wallet ID"
      />

      <Stack
        sx={{ marginBottom: '1rem', gap: 2 }}
        alignItems="center"
        justifyContent="flex-end"
        direction="row"
        width="100%"
      >
        <Button
          variant="outlined"
          sx={{ height: '32px', marginLeft: 'auto' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          size="small"
          loading={isSubmitting || loading}
          sx={{
            color: 'white',
            width: 'fit-content',

            backgroundColor: '#106E58',
            '&:hover': {
              backgroundColor: '#0b4e3e',
              boxShadow: 'unset',
            },
          }}
          color="warning"
        >
          Save Changes{' '}
        </LoadingButton>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <Dialog
        fullScreen={isMobile ? smallScreen : fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Wallet Address' : 'Create Wallet Address'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '350px' : '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderForm}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
