import PropTypes from 'prop-types';

import { Paper, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function ReferredByDetails({ memberDetails }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px dashed #ccee',
        py: 3.5,
        px: 2,
        borderRadius: 3,
      }}
    >
      {memberDetails?.referral_member?.name ? (
        <Stack flexDirection="" alignItems="start" justifyContent="start">
          <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>
          <Stack gap={2} ml={1}>
            <Typography sx={{ fontSize: 14, color: '#212B36' }}>
              {memberDetails?.referral_member?.name}
            </Typography>

            <Typography sx={{ fontSize: 14, color: '#212B36' }}>
              {memberDetails?.referral_member?.member_id}
            </Typography>
            <Typography sx={{ fontSize: 14, color: '#212B36' }}>
              {' '}
              {memberDetails?.referral_member?.email}
            </Typography>
            <Typography sx={{ fontSize: 14, color: '#212B36' }}>
              {memberDetails?.referral_member?.phone}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>no data</Typography>
      )}
    </Paper>
  );
}
ReferredByDetails.propTypes = {
  memberDetails: PropTypes.object,
};
