import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Tab, Tabs, alpha } from '@mui/material';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function WithdrawalRequestSort({ sort, setSort, dataList }) {
  const theme = useTheme();
  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'default',
      count: dataList?.withdrawalRequestCounts?.allWithdrawalRequestCount,
    },
    {
      value: 'PROCESSING',
      label: 'Processing',
      color: 'warning',
      count: dataList?.withdrawalRequestCounts?.pendingWithdrawalRequestCount,
    },
    {
      value: 'IN_PROGRESS',
      label: 'In Progress',
      color: 'info',
      count: dataList?.withdrawalRequestCounts?.inProgressWithdrawalCount,
    },
    {
      value: 'APPROVED',
      label: 'Approved',
      color: 'success',
      count: dataList?.withdrawalRequestCounts?.approvedWithdrawalRequestCount,
    },
    {
      value: 'CANCELLED',
      label: 'Cancelled',
      color: 'error',
      count: dataList?.withdrawalRequestCounts?.cancelledWithdrawalRequestCount,
    },
  ];

  return (
    <Tabs
      value={sort}
      onChange={(e, newValue) => {
        setSort(newValue);
      }}
      sx={{
        px: 2.5,
        boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}
    >
      {TABS?.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          iconPosition="end"
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
              color={tab.color}
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </Tabs>
  );
}

WithdrawalRequestSort.propTypes = {
  setSort: PropTypes.func,
  sort: PropTypes.string,
  dataList: PropTypes.any,
};
