/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import KeyIcon from '@mui/icons-material/Key';
import BlockIcon from '@mui/icons-material/Block';
import PersonIcon from '@mui/icons-material/Person';
import WalletIcon from '@mui/icons-material/Wallet';
import WarningIcon from '@mui/icons-material/Warning';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { Button, Tooltip, CardMedia, Typography, IconButton } from '@mui/material';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';

import { useParams } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDateSimple } from 'src/utils/format-time';

import { bgGradient } from 'src/theme/css';
import copy from 'src/assets/other/copy2.png';
import { frontEndUrl } from 'src/server/api/http';
import { memberBlock } from 'src/server/api/member';
import coverUrl from 'src/assets/other/set-role.jpg';

import { ConfirmDialog } from 'src/components/custom-dialog';

import FlagDialog from 'src/sections/Member/MemberSingleView/flagDialog';
import SuspendDialog from 'src/sections/Member/MemberSingleView/suspendDialog';

export default function MemberRefferedCard({
  memberDetails,
  setOpenModalPasswordDialog,
  setOpenModalDialog,
  data,
  memberID,
}) {
  const { isBlocked, isSuspented } = useSelector((state) => ({
    flagMessage: state.member.memberDetails?.note,
    isBlocked: state.member.memberDetails?.is_blocked,
    isSuspented: state.member.memberDetails?.is_withdrawal_suspended,
  }));
  // console.log(memberDetails);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const role = useGetRoles()?.role;
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const confirm = useBoolean();
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useResponsive('down', 'md');

  // Block_Unblock_Member
  const Block_Unblock_Member = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_blocked: !isBlocked,
      },
    };
    dispatch(memberBlock(credentials));
  };

  // flagfun
  const flagfun = () => {
    setOpenFlagDialog(true);
  };

  return (
    <>
      {role === 'member' && (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          sx={{
            ...bgGradient({
              color: alpha('#0a0909cc', 0.7),
              imgUrl: coverUrl,
            }),
            // backgroundColor: '#FFE0A3',
            // height: '201px',
            // height: isMobile ? '458px' : '100%',
            height: '100%',
            width: '100%',
            color: 'common.white',
            // border: '2px solid orange',
            borderRadius: '15px',
          }}
          justifyContent="space-between"
        >
          <Stack
            // border='2px solid orange'
            justifyContent="space-between"
            p={2}
          >
            <Stack
              sx={{
                // position: 'absolute',
                // top: 60,
                // left: 16,
                color: 'common.white',
              }}
              // direction='row'
            >
              <Typography variant="body2">Current Club</Typography>
              <Typography
                variant="body2"
                sx={{
                  opacity: 0.48,
                }}
              >
                {/* CIAN (Active Member) */}
                {memberDetails?.current_club?.club?.title || ''}
              </Typography>
              {/* <Typography
              sx={{
                typography: 'caption',
                opacity: 0.48,
              }}
            >
              You can only withdraw
              {memberDetails?.current_club?.club?.limit_amount || ''} {USDT}
            </Typography> */}
            </Stack>

            <Stack
              sx={{
                // position: 'absolute',
                // bottom: 57,
                // left: 16,
                color: 'common.white',
              }}
              // direction='row'
            >
              <Typography variant="body2">Wallet Balance</Typography>
              <Typography
                sx={{
                  typography: 'caption',
                  opacity: 0.68,
                }}
              >
                {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
              </Typography>
            </Stack>

            <Stack direction="row">
              <IconButton
                onClick={() => setOpenModalDialog(true)}
                sx={{
                  // position: 'absolute',
                  // top: 16,
                  // left: 16,
                  color: 'common.white',
                  width: '40px',
                  // border: '2px solid orange'
                }}
              >
                <Tooltip title="Update Info">
                  <EditRoundedIcon sx={{ fontSize: '20px' }} />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => setOpenModalPasswordDialog(true)}
                sx={{
                  color: 'common.white',
                  width: '40px',
                }}
              >
                <Tooltip title="Update Password">
                  <KeyIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            // border='2px solid orange'
            justifyContent="center"
            flexDirection=""
            // alignItems="start"
            // justifyContent="start"
            sx={{
              // position: 'absolute',
              // bottom: 57,
              // right: 66,
              color: 'common.white',
            }}
            p={2}
          >
            {data?.referral_member ? (
              <>
                <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

                <Stack gap={1} ml={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {data?.referral_member?.member_id}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {data?.referral_member?.name}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <MarkEmailUnreadRoundedIcon
                      sx={{ fontSize: 14, color: 'white' }}
                      fontSize="small"
                    />{' '}
                    &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {' '}
                      {data?.referral_member?.email}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <LocalPhoneRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
                    &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {data?.referral_member?.phone}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            ) : (
              <Stack
                // border='2px solid orange'
                mr={3}
              >
                <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

                <Typography
                  mt={8}
                  sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: 'center' }}
                >
                  Nil
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}

      {role === 'admin' && (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{
            ...bgGradient({
              color: alpha('#0a0909cc', 0.7),
              imgUrl: coverUrl,
            }),
            // backgroundColor: '#FFE0A3',
            // height: isMobile ? '458px' : '201px',
            // height: isMobile ? '458px' : '100%',
            height: '100%',

            width: '100%',
            color: 'common.white',
            borderRadius: '15px',

            // border: '2px solid orange',
          }}
        >
          <Stack
            // border='2px solid orange'
            justifyContent="space-between"
            p={2}
          >
            <Stack
              sx={{
                // position: 'absolute',
                // top: 60,
                // left: 16,
                color: 'common.white',
              }}
              // direction='row'
            >
              <Typography variant="body2">Current Club</Typography>
              <Typography
                variant="body2"
                sx={{
                  opacity: 0.48,
                }}
              >
                {/* CIAN (Active Member) */}
                {memberDetails?.current_club?.club?.title || 'No active club'}
              </Typography>
              {/* <Typography
              sx={{
                typography: 'caption',
                opacity: 0.48,
              }}
            >
              You can only withdraw
              {memberDetails?.current_club?.club?.limit_amount || ''} {USDT}
            </Typography> */}
            </Stack>

            <Stack
              sx={{
                // position: 'absolute',
                // bottom: 57,
                // left: 16,
                color: 'common.white',
              }}
              // direction='row'
            >
              <Typography variant="body2">Wallet Balance</Typography>
              <Typography
                sx={{
                  typography: 'caption',
                  opacity: 0.68,
                }}
              >
                {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <CopyToClipboard
                // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                onCopy={handleCopy}
                sx={
                  {
                    // cursor: 'pointer',
                    // position: 'absolute',
                    // bottom: 16,
                    // left: 16,
                    // '&:hover': {
                    //   color: 'primary.main',
                    // },
                  }
                }
              >
                <Button
                  size="small"
                  variant="contained"
                  startIcon={
                    <CardMedia component="img" src={copy} alt="copy" sx={{ width: '15px' }} />
                  }
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    color: 'white',
                    backgroundColor: '#00A76F14',
                    p: 2,
                    '&:hover': { backgroundColor: '#00A76F14' },
                  }}
                >
                  Copy Referral Link{' '}
                </Button>
              </CopyToClipboard>
              <IconButton
                onClick={() => setOpenModalDialog(true)}
                sx={{
                  // position: 'absolute',
                  // top: 16,
                  // left: 16,
                  width: '40px',
                  color: 'common.white',
                }}
              >
                <Tooltip title="Update Info">
                  <EditRoundedIcon sx={{ fontSize: '18px' }} />
                </Tooltip>
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            flexDirection=""
            // alignItems="start"
            // justifyContent="start"
            sx={{
              // position: 'absolute',
              // bottom: 26,
              // right: 15,
              color: 'common.white',
            }}
            // width="50%"
          >
            {memberDetails?.referral_member ? (
              <>
                {/* <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

                <Stack gap={1} ml={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {data?.referral_member?.member_id}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {data?.referral_member?.name}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <MarkEmailUnreadRoundedIcon
                      sx={{ fontSize: 14, color: 'white' }}
                      fontSize="small"
                    />{' '}
                    &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {' '}
                      {data?.referral_member?.email}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <LocalPhoneRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
                    &nbsp;
                    <Typography variant="body2" sx={{ color: 'white' }}>
                      {data?.referral_member?.phone}
                    </Typography>
                  </Stack>
                </Stack> */}

                <Stack
                  // width="335px"
                  mt={5}
                >
                  <Typography
                    sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: isMobile && 'center' }}
                  >
                    Referred by
                  </Typography>
                  <Stack
                    direction="row"
                    // border='2px solid orange'
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      gap={1}
                      ml={1}
                      // border='2px solid orange'
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
                        &nbsp;
                        <Typography variant="body2" sx={{ color: 'white' }}>
                          {memberDetails?.referral_member?.member_id}
                        </Typography>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
                        <Typography variant="body2" sx={{ color: 'white' }}>
                          {memberDetails?.referral_member?.name}
                        </Typography>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <MarkEmailUnreadRoundedIcon
                          sx={{ fontSize: 14, color: 'white' }}
                          fontSize="small"
                        />{' '}
                        &nbsp;
                        <Typography variant="body2" sx={{ color: 'white' }}>
                          {' '}
                          {memberDetails?.referral_member?.email}
                        </Typography>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <LocalPhoneRoundedIcon
                          sx={{ fontSize: 14, color: 'white' }}
                          fontSize="small"
                        />{' '}
                        &nbsp;
                        <Typography variant="body2" sx={{ color: 'white' }}>
                          {memberDetails?.referral_member?.phone}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  {/* <Typography
                    variant="body2"
                    color="grey"
                    pr={2}
                    textAlign="center"
                    m={1}
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      maxWidth: '335px',
                    }}
                  >
                    {flagMessage}
                  </Typography> */}
                </Stack>
              </>
            ) : (
              <Stack
                // border='2px solid orange'
                mt={5}
                // width="100%"
              >
                <Stack
                  sx={
                    {
                      // position: 'absolute',
                      // bottom: 45,
                      // right: 96,
                      // width: '100px',
                      // border: '2px solid orange'
                    }
                  }
                >
                  <Typography
                    sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: isMobile && 'center' }}
                  >
                    Referred by
                  </Typography>

                  <Typography
                    mt={8}
                    sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: 'center' }}
                  >
                    Nil
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>

          <Stack
            textAlign="end"
            // border='2px solid orange'
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <Stack spacing={1} direction={isMobile ? 'row' : 'column'}>
              <Tooltip title={isBlocked ? 'Unblock Member' : 'Block Member'}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ color: 'white' }}
                  startIcon={<BlockIcon />}
                  onClick={() => confirm.onTrue()}
                >
                  <Stack>
                    {isBlocked ? 'Unblock' : 'Block'}
                    {isBlocked && (
                      <Typography
                        sx={{
                          fontSize: '9px',
                        }}
                      >
                        {fDateSimple(memberDetails?.blocked_at)}
                      </Typography>
                    )}
                  </Stack>
                </Button>
              </Tooltip>

              <Tooltip title={isSuspented ? 'Unsuspend Withdrawal' : 'Suspend Withdrawal'}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ color: 'white' }}
                  startIcon={<WalletIcon />}
                  // onClick={memberSuspentfun}
                  onClick={() => setOpenSuspendDialog(true)}
                >
                  <Stack>
                    {isSuspented ? 'Unsuspend' : 'Suspend'}
                    {isSuspented && (
                      <Typography
                        sx={{
                          fontSize: '9px',
                        }}
                      >
                        {fDateSimple(memberDetails?.last_withdrawal_suspended_at)}
                      </Typography>
                    )}
                  </Stack>
                </Button>
              </Tooltip>

              <Tooltip title="Flag Member">
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ color: 'white' }}
                  startIcon={<WarningIcon />}
                  onClick={flagfun}
                >
                  <Stack>
                    {/* {flagMessage && flagMessage.trim() ? 'Flagged' : 'Flag'} */}
                    {memberDetails?.is_flagged ? 'Unflag' : 'Flag'}
                    {memberDetails?.is_flagged && (
                      <Typography
                        sx={{
                          fontSize: '9px',
                        }}
                      >
                        {fDateSimple(memberDetails?.last_flagged_date)}
                      </Typography>
                    )}
                  </Stack>
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      )}

      <ConfirmDialog
        open={confirm.value}
        // open='true'
        onClose={confirm.onFalse}
        title={isBlocked ? 'Unblock Member' : 'Block Member'}
        content={<>Are you sure want to {isBlocked ? 'unblock member' : 'block member'}?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Block_Unblock_Member();
              confirm.onFalse();
            }}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </Button>
        }
      />

      <FlagDialog
        open={openFlagDialog}
        setOpen={setOpenFlagDialog}
        flagMessage={memberDetails?.note}
        is_flagged={memberDetails?.is_flagged}
      />
      <SuspendDialog
        open={openSuspendDialog}
        setOpen={setOpenSuspendDialog}
        isSuspented={isSuspented}
      />
    </>
  );
}

MemberRefferedCard.propTypes = {
  memberDetails: PropTypes.string,
  setOpenModalPasswordDialog: PropTypes.func,
  setOpenModalDialog: PropTypes.func,
  data: PropTypes.object,
  memberID: PropTypes.string,
};
