/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createMember = createAsyncThunk(
  'member/create',
  async ({ state, dispatch, navigate }) => {
    try {
      const URL = `/member/admin/new`;
      const response = await post(URL, state);
      if (response) {
        navigate(paths?.dashboard?.member_view(response?._id));
        dispatch(activeSnack({ type: 'success', message: 'Member created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllMembers = createAsyncThunk(
  'member/list',
  async ({ page, search, dispatch, limit, sort, date, referralMember, sort_order }) => {
    try {
      const response = await get(
        `/member/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&sort=${sort || ''}&dateFrom=${date?.start_date || ''}&dateTo=${
          date?.end_date || ''
        }&referral_member=${referralMember || ''}&sort_order=${sort_order || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllReferralMembers = createAsyncThunk(
  'member/referral',
  async ({ page, search, dispatch }) => {
    try {
      const response = await get(
        `/member/admin/referral?page=${page && page}&search=${search && search}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberClubIncomeTransactions = createAsyncThunk(
  'member/club-income-transactions',
  async ({
    page,
    search,
    dispatch,
    date,
    selectedClub,
    id,
    limit,
    transactionTypeValues,
    selectedMember,
    sort,
  }) => {
    // console.log('dateValue =>', date);
    try {
      if (id) {
        const response = await get(
          // eslint-disable-next-line no-constant-condition
          `/club-income-transaction/admin/all?page=${page && page}&search=${
            search || ''
            // eslint-disable-next-line no-constant-condition
          }&dateFrom=${date ? date[0] : ''}&dateTo=${date ? date[1] : ''}&club=${
            selectedClub || ''
          }&member=${id || ''}`
        );
        return response;
      }
      const response = await get(
        // eslint-disable-next-line no-constant-condition
        `/club-income-transaction/admin/all?page=${page && page}&search=${
          search || ''
          // eslint-disable-next-line no-constant-condition
        }&dateFrom=${date ? date[0] : ''}&dateTo=${date ? date[1] : ''}&club=${
          selectedClub || ''
        }&limit=${limit || 25}&transaction_type=${transactionTypeValues || ''}&member=${
          selectedMember || ''
        }&sort=${sort || ''}`
      );
      return response;

      // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log('error =>', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberDetails = createAsyncThunk(
  'member/single',
  async ({ memberId, dispatch }) => {
    try {
      const response = await get(`/member/admin/${memberId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberDetails = createAsyncThunk(
  'member/update',
  async ({ state, dispatch, handleClose, handleClear, navigate }) => {
    try {
      const URL = `/member/admin/${state?.id}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          getMemberDetails({
            memberId: response?._id,
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Member updated Successfully' }));
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberStatus = createAsyncThunk(
  'member/update/status',
  async ({ state, memberId, dispatch }) => {
    try {
      const URL = `/member/admin/status/${memberId}`;

      const response = await post(URL);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Member Status updated Successfully' }));
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const updateMemberPaymentAdmin = createAsyncThunk(
  'member/update/payment',
  async ({ state, memberId, dispatch }) => {
    try {
      const URL = `member/admin/payment/${memberId}`;

      const response = await post(URL, state);
      if (response) {
        dispatch(
          getMemberDetails({
            dispatch,
            memberId: response?._id,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Payment updated Successfully' }));
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteMember = createAsyncThunk(
  'member/delete',
  async ({ memberId, dispatch, handleCloseDeleteDialog, navigate, bool }) => {
    try {
      const response = await del(`/member/admin/${memberId}/`);
      if (response) {
        handleCloseDeleteDialog();
        if (bool === true) {
          navigate(-1);
        }
        dispatch(activeSnack({ type: 'success', message: 'Member deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const memberPaymentUpload = createAsyncThunk(
  'member/payment',
  async ({ state, dispatch, enqueueSnackbar, handleClose }) => {
    try {
      const URL = `member/payment`;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getMemberProfileDetails({
            dispatch,
          })
        );
        // dispatch(activeSnack({ type: 'success', message: response.message }));
        enqueueSnackbar(' Uploaded Successfully!');
        if (handleClose) {
          handleClose();
        }
        return response;
      }
      return false;
    } catch (error) {
      throw error?.response?.data?.message;
    }
  }
);

// member profile in case of register
export const getMemberProfileDetails = createAsyncThunk('member/me', async ({ dispatch }) => {
  try {
    const response = await get(`member/me`);
    // console.log(response);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const updateMemberPassword = createAsyncThunk(
  'password/update',
  async ({ state, enqueueSnackbar, handleClose }) => {
    try {
      const response = await post(`password/update`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');

        // navigate(paths?.auth?.login);
        if (handleClose) {
          handleClose();
        }
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updateMemberProfileDetails = createAsyncThunk(
  'member/profile/update',
  async ({ state, enqueueSnackbar, dispatch, handleClose }) => {
    try {
      const response = await post(`member/update`, state);

      if (response) {
        dispatch(
          getMemberProfileDetails({
            dispatch,
          })
        );

        enqueueSnackbar('Profile Updated Successfully!');
        if (handleClose) {
          handleClose();
        }
        // navigate(paths?.auth?.login);

        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const getRoleFromUser = createAsyncThunk('role/get', async ({ state, dispatch }) => {
  try {
    const response = await post(`/verify`, state);
    // console.log(response);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// withdrawals
export const getWithdrawalsList = createAsyncThunk(
  'withdraw/list',
  async ({ dispatch, memberId }) => {
    try {
      const response = await get(`/withdrawal-request/admin/all?member=${memberId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// member block post api
export const memberBlock = createAsyncThunk(
  'member/block',
  async ({ dispatch, memberId, state }) => {
    try {
      const response = await post(`/member/admin/block/${memberId}`, state);
      if (response) {
        dispatch(
          getMemberDetails({
            memberId: response?._id,
            dispatch,
          })
        );
        if (state?.is_blocked) {
          dispatch(activeSnack({ type: 'success', message: 'Blocked successfully !!' }));
        }
        if (state?.is_blocked === false) {
          dispatch(activeSnack({ type: 'success', message: 'Unblocked successfully !!' }));
        }
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// member block get api

export const getmemberBlock = createAsyncThunk(
  'getmember/block',
  async ({ dispatch, memberId }) => {
    try {
      // const response = await get("member/admin/all?sort=BLOKED")
      const response = await get(`member/admin/${memberId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// suspent member post api
export const memberSuspend = createAsyncThunk(
  'member/Suspent',
  async ({ dispatch, memberId, state, handleClose }) => {
    try {
      const response = await post(`/member/admin/withdrawal-suspension/${memberId}`, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          getMemberDetails({
            memberId: response?._id,
            dispatch,
          })
        );
        if (state?.is_withdrawal_suspended) {
          dispatch(activeSnack({ type: 'success', message: 'Withdrawl Suspend success !!' }));
        }
        if (state?.is_withdrawal_suspended === false) {
          dispatch(activeSnack({ type: 'success', message: 'Withdrawl UnSuspend success !!' }));
        }

        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// suspent member get api
// export const getmemberSuspent = createAsyncThunk(
//   'getmember/Suspent',
//   async ({ dispatch, memberId, state }) => {
//     try {
//       const response = await post(`/member/admin/withdrawal-suspension/${memberId}`, state);
//       if (response) {
//         dispatch(activeSnack({ type: 'success', message: 'Susspent success !!' }));
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

// flag member
export const flagMember = createAsyncThunk(
  'member/flag',
  async ({ dispatch, memberId, state, handleClose, note, flag }) => {
    try {
      const response = await post(`/member/admin/flagging/${memberId}`, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          getMemberDetails({
            memberId: response?._id,
            dispatch,
          })
        );
        if (note) {
          dispatch(activeSnack({ type: 'success', message: 'Note added successfully !!' }));
        }
        if (flag && state?.is_flagged) {
          dispatch(activeSnack({ type: 'success', message: 'Flagged successfully !!' }));
        }
        if (flag && state?.is_flagged === false) {
          dispatch(activeSnack({ type: 'success', message: 'Unflagged successfully !!' }));
        }
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
