/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
import { Card, Stack, Button } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllClubs } from 'src/server/api/club';
import stack3 from 'src/assets/Dashboard/stack3.png';
import stack5 from 'src/assets/Dashboard/stack5.png';
import stack6 from 'src/assets/Dashboard/stack6.png';
import { getSettingsDetails } from 'src/server/api/settings';
import { syncDashboardData } from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';

import AllClub from 'src/sections/clubIncomeTransaction/editClub';
import MiniCards from 'src/sections/clubIncomeTransaction/mini-cards';
import IncomeWidgetSummary from 'src/sections/income/income-widget-summary';

import PartnerCheckInWidgets from './admin-check-in-widgets';
import DashboardTransaction from './dashboard/Tables/TransactionPage';
import AdminDashboardVerifiedList from './dashboard/UnverifiedMemberList';
// ----------------------------------------------------------------------

const SPACING = 2;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');
  const settings = useSettingsContext();
  const { role } = useGetRoles();

  const { dashboardData, clubList } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    loading: state.dashboard.loading,
    earningsChartData: state.dashboard.earningsChart,
    salesChart: state.dashboard.salesChart,
    clubList: state.club.clubs?.clubs,
    settingsData: state.settings.settingsDetails,
    // transactionList: state.transaction.transactions,
  }));
  // useEffect(() => {
  //   const credentials = {
  //     page: '',
  //     limit: '',
  //     transaction_type: '',
  //     date: '',
  //     dispatch,
  //     member: '',
  //     wallet_type: '',
  //     selectedCategory: '',
  //   };
  //   dispatch(getAllTransactionsAdmin(credentials));
  // }, [dispatch]);
  // console.log(settingsData);
  useEffect(() => {
    dispatch(getAllClubs(dispatch));
  }, [dispatch]);

  useEffect(() => {
    if (role === 'admin') {
      const credentials = {
        dispatch,
      };
      dispatch(getSettingsDetails(credentials));
    }
  }, [dispatch, role]);

  const handleClick = () => {
    dispatch(syncDashboardData(enqueueSnackbar));
    console.log('Sync Dashboard Data!');
  };

  // const { totalSalesCount, totalSalesValue, totalEarningsValue, totalEarningsCount } =
  //   dashboardData;
  // const averageSales = totalSalesValue / totalSalesCount;

  // const averageEarnings = totalEarningsValue / totalEarningsCount;

  return (
    <Grid container spacing={SPACING} disableEqualOverflow>
      <Grid
        xs={12}
        md={12}
        // gap={2}
        sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-end',
          margin: -3,
          padding: 0,
        }}
      >
        <Button
          size="small"
          // variant="outlined"
          startIcon={<ChangeCircleIcon />}
          onClick={handleClick}
          sx={{ color: '#00A76F', width: '110px', height: '34px' }}
        >
          Sync Data
        </Button>
      </Grid>
      <Grid xs={12} md={12}>
        <PartnerCheckInWidgets
          chart={{
            series: [
              {
                label: 'Members',
                total: dashboardData?.membersCount?.allMembersCount || 0,
                img: stack3,
                link: paths.dashboard.member,
                unPaid: dashboardData?.membersCount?.unpaidMembersCount || 0,
                unVerified: dashboardData?.membersCount?.unverifiedMembersCount || 0,
                verified: dashboardData?.membersCount?.verifiedMembersCount || 0,
                iconDown: (
                  <DangerousRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#FF5630',
                    }}
                  />
                ),
                iconUp: (
                  <VerifiedRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#22C55E',
                    }}
                  />
                ),
                iconDownRed: (
                  <NewReleasesIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#FF5630',
                    }}
                  />
                ),
              },
              {
                label: 'Clubs',
                total: dashboardData?.clubsCount?.allClubsCount || 0,
                link: paths.dashboard.club,
                img: stack5,
                clubMembers: dashboardData?.clubsCount?.allClubMembersCount || 0,
                activeMembers: dashboardData?.clubsCount?.allActiveClubMembersCount || 0,
                iconUsers: (
                  <GroupRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#637381',
                    }}
                  />
                ),
                iconUserSuccess: (
                  <PersonAddAlt1RoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#118D57',
                    }}
                  />
                ),
              },
              {
                label: 'Withdrawal Request',
                total: dashboardData?.withdrawalRequestsCount?.allWithdrawalRequestCount,
                img: stack6,
                link: paths.dashboard.withdrawal_requests,
                pending: dashboardData?.withdrawalRequestsCount?.pendingWithdrawalRequestCount || 0,
                approved:
                  dashboardData?.withdrawalRequestsCount?.approvedWithdrawalRequestCount || 0,
                cancelled:
                  dashboardData?.withdrawalRequestsCount?.cancelledWithdrawalRequestCount || 0,
                iconPending: (
                  <WarningRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#f0ad4e',
                    }}
                  />
                ),
                iconApproved: (
                  <VerifiedRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#118D57',
                    }}
                  />
                ),
                iconCancelled: (
                  <CancelRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#B71D18',
                    }}
                  />
                ),
              },
            ],
          }}
        />
      </Grid>
      {/* <Grid
        xs={12}
        md={12}
        gap={2}
        sx={{ flexDirection: isMobile ? 'column' : 'row', display: 'flex' }}
      >
        <Stack gap={2} flexDirection={isMobile ? 'column' : 'row'} width="100%">
          <IncomeWidgetSummary
            sx={{ width: '100%' }}
            backgroundColor={settings?.themeMode === 'dark' ? '' : '#eefff4'}
            title="Actual Wallet"
            total={dashboardData?.walletSummary?.companyWallets?.actual_wallet_balance || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
          <IncomeWidgetSummary
            sx={{ width: '100%' }}
            title="Member Wallet Balance"
            total={dashboardData?.walletSummary?.memberWalletBalanceTotal || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
        </Stack>
      </Grid> */}
      <Grid
        xs={12}
        md={12}
        gap={2}
        sx={{ flexDirection: isMobile ? 'column' : 'row', display: 'flex' }}
      >
        {/* <AdminDashboardWallet settingsData={settingsData} /> */}

        <Stack gap={2} flexDirection={isMobile ? 'column' : 'row'} width="100%">
          <IncomeWidgetSummary
            variantValue="h6"
            sx={{ width: '100%' }}
            backgroundColor={settings?.themeMode === 'dark' ? '' : '#eefff4'}
            title="Actual Wallet"
            total={dashboardData?.walletSummary?.companyWallets?.actual_wallet_balance || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
          <IncomeWidgetSummary
            variantValue="h6"
            sx={{ width: '100%' }}
            title="Member Wallet"
            total={
              dashboardData?.walletSummary?.walletEarningsStatistics?.member_wallet_balance_total ||
              0
            }
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
          <IncomeWidgetSummary
            variantValue="h6"
            sx={{ width: '100%' }}
            title="Company Wallet"
            total={dashboardData?.walletSummary?.companyWallets?.company_wallet_amount || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
          <IncomeWidgetSummary
            variantValue="h6"
            sx={{ width: '100%' }}
            title="Maintenance Wallet"
            total={dashboardData?.walletSummary?.companyWallets?.maintenance_wallet_amount || 0}
            icon="solar:sort-by-time-bold-duotone"
            text="wallet is for covering maintenance costs associated with systems"
          />
          {/* </Stack>
        <Stack> */}
          <IncomeWidgetSummary
            variantValue="h6"
            sx={{ width: '100%' }}
            title="Future Projects Wallet"
            total={dashboardData?.walletSummary?.companyWallets?.future_project_wallet_amount || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="Funds allocated for future projects, innovations, and expansions."
          />
        </Stack>
      </Grid>
      <Grid
        xs={12}
        md={12}
        sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
        gap={2}
      >
        {/* <AllClub clubList={clubList} dashboard /> */}
        <Stack flex={12} flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <MiniCards
            icon="solar:share-bold"
            sx={{ backgroundColor: '#313944' }}
            total={dashboardData?.walletSummary?.walletEarningsStatistics?.catalyst_total || 0}
            title="Total Catalyst Earnings "
          />
          <MiniCards
            icon="ph:lightning-fill"
            color="info"
            sx={{ backgroundColor: '#313944' }}
            total={dashboardData?.walletSummary?.walletEarningsStatistics?.line_total || 0}
            title="Total Connect Earnings "
          />
          <MiniCards
            icon="vaadin:money-deposit"
            color="info"
            sx={{ backgroundColor: '#313944' }}
            total={dashboardData?.walletSummary?.walletEarningsStatistics?.fourth_total || 0}
            title="Total Fourth Earnings "
          />
          <MiniCards
            icon="mdi:recurring-payment"
            color="info"
            sx={{ backgroundColor: '#313944' }}
            total={dashboardData?.walletSummary?.walletEarningsStatistics?.incentive_total || 0}
            title="Total Incentive Earnings "
          />
        </Stack>
        {/* <Stack flex={12} height="100%">
          <Card
            sx={
              {
                //  height: '100%',
                // display: !isMobile && 'flex', alignItems: !isMobile &&'center', justifyContent:!isMobile && 'center'
              }
            }
          >
            {' '}
            <AllClub clubList={clubList} dashboard />
          </Card>
        </Stack> */}
      </Grid>
      <Grid
        xs={12}
        md={12}
        sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
        gap={2}
      >
        <Stack flex={12} height="100%">
          <Card
            sx={
              {
                //  height: '100%',
                // display: !isMobile && 'flex', alignItems: !isMobile &&'center', justifyContent:!isMobile && 'center'
              }
            }
          >
            {' '}
            <AllClub clubList={clubList} dashboard />
          </Card>
        </Stack>
      </Grid>

      {/* <Grid xs={12} md={8}>
        <DashboardTransaction count={5} />
      </Grid>
      <Grid md={4} xs={12}>
        <AdminDashboardVerifiedList />{' '}
      </Grid> */}
      <Grid
        xs={12}
        md={12}
        sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
        gap={2}
      >
        <Stack flex={8}>
          <DashboardTransaction disable="disable" count={5} />
        </Stack>
        <Stack flex={4}>
          <AdminDashboardVerifiedList sx={{ height: '100%' }} />
        </Stack>
      </Grid>
    </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
