/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { Box, Grid, Stack, CardMedia, Container, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import bioniumCoinImg from 'src/assets/logo/bionuim.png';
import bioniumCoinImgDark from 'src/assets/logo/bionuim-dark.png';

import { useSettingsContext } from 'src/components/settings';

import BioniumPage from './BioniumPage';

function Incentive() {
  const { role } = useGetRoles();
  const theme = useSettingsContext();
  const useTheme = theme?.themeMode;
  const isMobile = useResponsive('down', 'md');
  const now = new Date();

  const targetDate = new Date('2024-10-10T24:00:00');
  console.log(now >= targetDate);

  const renderContent = () => {
    if (role === 'admin') {
      return <BioniumPage />;
    }

    if (now >= targetDate) {
      return <BioniumPage />;
    }
    return <TimerComponent useTheme={useTheme} isMobile={isMobile} />;
  };

  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Incentives" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>{renderContent()}</Container>
    </>
  );
}

export default Incentive;

const TimerComponent = ({ useTheme, isMobile }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const targetDate = new Date('2024-10-10T24:00:00');
  const calculateTimeRemaining = useCallback(() => {
    const today = new Date();
    const timeRemaining = targetDate.getTime() - today.getTime();

    const oneDay = 1000 * 60 * 60 * 24;
    const oneHour = 1000 * 60 * 60;
    const oneMinute = 1000 * 60;
    const oneSecond = 1000;

    const days = Math.floor(Math.max(timeRemaining / oneDay, 0));
    const hours = Math.floor(Math.max((timeRemaining % oneDay) / oneHour, 0));
    const minutes = Math.floor(Math.max((timeRemaining % oneHour) / oneMinute, 0));
    const seconds = Math.floor(Math.max((timeRemaining % oneMinute) / oneSecond, 0));

    return { days, hours, minutes, seconds };
  }, [targetDate]);

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining();
      setTimeRemaining(newTimeRemaining);

      if (
        newTimeRemaining.days === 0 &&
        newTimeRemaining.hours === 0 &&
        newTimeRemaining.minutes === 0 &&
        newTimeRemaining.seconds === 0
      ) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [calculateTimeRemaining]);

  return (
    <Grid
      display={isMobile ? '' : 'flex'}
      alignItems="center"
      justifyContent="center"
      className="timer-background"
      textAlign="center"
      mt={6}
    >
      <Box>
        <CardMedia
          component="img"
          src={useTheme === 'dark' ? bioniumCoinImgDark : bioniumCoinImg}
          height={isMobile ? 200 : 220}
          width={isMobile ? 200 : 220}
          sx={{ objectFit: 'contain' }}
          alt="bionium image"
        />
        <Typography
          display="flex"
          justifyContent="center"
          pt={3}
          className="timer-title"
          fontSize={36}
          fontWeight={800}
        >
          Bionium AI
        </Typography>
        <Typography
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
          textAlign={isMobile && 'center'}
          fontSize={13}
        >
          On the road to the Bionium AI token distribution, a new era in digital currency begins in
        </Typography>

        <Box display={isMobile ? '' : 'flex'} justifyContent="center" pt={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyItems="center"
            spacing={isMobile ? 3 : 8}
            pl={isMobile && 10}
          >
            {/* Days */}
            <Box>
              <Typography
                fontSize={40}
                fontWeight={600}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
              >
                {timeRemaining.days}
              </Typography>
              <Typography
                fontSize={10}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
                fontWeight="400"
              >
                DAYS
              </Typography>
            </Box>

            {/* Divider */}
            <Box
              sx={{
                height: 40,
                width: 2,
                opacity: '20%',
                backgroundColor: useTheme === 'dark' ? '#E6E6E6' : '#151515',
                borderRadius: '50px',
              }}
            />

            {/* Hours */}
            <Box>
              <Typography
                fontSize={40}
                fontWeight={600}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
              >
                {timeRemaining.hours}
              </Typography>
              <Typography
                fontSize={10}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
                fontWeight="400"
              >
                HOURS
              </Typography>
            </Box>

            {/* Divider */}
            <Box
              sx={{
                height: 40,
                width: 2,
                opacity: '20%',
                backgroundColor: useTheme === 'dark' ? '#E6E6E6' : '#151515',
                borderRadius: '50px',
              }}
            />

            {/* Minutes */}
            <Box>
              <Typography
                fontSize={40}
                fontWeight={600}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
              >
                {timeRemaining.minutes}
              </Typography>
              <Typography
                fontSize={10}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
                fontWeight="400"
                pl={0.8}
              >
                MINUTES
              </Typography>
            </Box>

            {/* Divider */}
            <Box
              sx={{
                height: 40,
                width: 2,
                opacity: '20%',
                backgroundColor: useTheme === 'dark' ? '#E6E6E6' : '#151515',
                borderRadius: '50px',
              }}
            />

            {/* Seconds */}
            <Box textAlign="center">
              <Typography
                fontSize={40}
                fontWeight={600}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
              >
                {timeRemaining.seconds}
              </Typography>
              <Typography
                fontSize={10}
                color={useTheme === 'dark' ? '#E6E6E6' : '#6E6D6D'}
                fontWeight="400"
              >
                SECONDS
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" pt={isMobile ? 5 : 10}>
          <Stack
            p={1}
            sx={{
              backgroundColor: useTheme === 'dark' ? '#2f2d2b' : '#FEF8F1',
              color: useTheme === 'dark' ? '#CAA877' : '#AD6600',
              borderRadius: 1,
            }}
          >
            <Typography fontSize={13}>Thursday, October 10, 2024</Typography>
          </Stack>
        </Box>
      </Box>
    </Grid>
  );
};

TimerComponent.propTypes = {
  useTheme: PropTypes.string,
  isMobile: PropTypes.string,
};
