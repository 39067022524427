import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Tab, Tabs, alpha } from '@mui/material';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function MemberSort({ sort, setSort, memberCount }) {
  const theme = useTheme();

  // console.log(memberCount);
  const TABS = [
    {
      value: 'ALL',
      label: 'All',
      color: 'default',
      count: memberCount?.allMembersCount || 0,
    },
    {
      value: 'UNPAID',
      label: 'Unpaid',
      color: 'default',
      count: memberCount?.unpaidMembersCount || 0,
    },
    {
      value: 'UNVERIFIED',
      // label: 'Unverified',
      label: 'Processing',
      color: 'default',
      count: memberCount?.unverifiedMembersCount || 0,
    },
    {
      value: 'VERIFIED',
      label: 'Verified',
      color: 'default',
      count: memberCount?.verifiedMembersCount || 0,
    },
    {
      value: 'EXPIRED',
      label: 'Expired',
      color: 'default',
      count: memberCount?.expiredMembersCount || 0,
    },
    {
      value: 'BLOCKED',
      label: 'Blocked',
      color: 'default',
      count: memberCount?.blockedMembersCount || 0,
    },
    {
      value: 'FLAGGED',
      label: 'Flagged',
      color: 'default',
      count: memberCount?.flaggedMembersCount || 0,
    },
    {
      value: 'WITHDRAWAL_SUSPENDED',
      label: 'Withdrawal Suspended',
      color: 'default',
      count: memberCount?.withdrawalSuspendedMembersCount || 0,
    },

    // {
    //   value: 'ACTIVE',
    //   label: 'Active',
    //   color: 'default',
    //   count: memberCount?.activeMembersCount || 0,
    // },
    // {
    //   value: 'INACTIVE',
    //   label: 'Inactive',
    //   color: 'default',
    //   count: memberCount?.inactiveMembersCount || 0,
    // },
  ];

  return (
    <Tabs
      className="custom-tab"
      value={sort}
      onChange={(e, newValue) => {
        setSort(newValue);
      }}
      sx={{
        px: 2.5,
        // pl:0,
        // px:.1,
        boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}
    >
      {TABS?.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          iconPosition="end"
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
              color={tab.color}
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </Tabs>
  );
}

MemberSort.propTypes = {
  setSort: PropTypes.func,
  sort: PropTypes.string,
  memberCount: PropTypes.any,
};
