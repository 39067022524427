import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { getAllClubsAdmin } from 'src/server/api/club';
import { getAllMembers, getMemberClubIncomeTransactions } from 'src/server/api/member';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ClubIncomeTransactionSort from 'src/sections/clubIncomeTransaction/ClubIncomeTransactionSort';
import ClubIncomeTransactionToolbar from 'src/sections/clubIncomeTransaction/ClubIncomeTransactionToolbar';
import ClubIncomeTransactionTableRow from 'src/sections/clubIncomeTransaction/ClubIncomeTransactionTableRow';

// ----------------------------------------------------------------------

export default function ClubTransactions({ disable }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const [date, setDate] = useState();
  const [formattedDate, setFormattedDate] = useState(['', '']);

  // console.log('formattedDate', date);

  useEffect(() => {
    if (date && date?.length > 1 && date[1] !== null) {
      const selectedRangeFormatted = date?.map((dates) => dayjs(dates).format('YYYY-MM-DD'));
      setFormattedDate(selectedRangeFormatted);
    }
  }, [date]);

  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  // const [transactionTypeValues, setTransactionTypeValue] = useState('');
  // console.log(setTransactionTypeValue);
  const {
    loading,
    sortCount,
    clubIncomeTransactionsCount,
    clubsAdmin,
    clubIncomeTransactions,
    membersList,
  } = useSelector((state) => ({
    loading: state.transaction.loading,
    clubsAdmin: state.club.clubsAdmin?.clubs,
    clubIncomeTransactionList: state.transaction.ClubIncomeTransactions,
    clubIncomeTransactions: state.member.clubIncomeTransactions?.clubIncomeTransactions,
    clubIncomeTransactionsCount: state.member.clubIncomeTransactions?.total,
    membersList: state.member.members?.members,
    sortCount: state.member.clubIncomeTransactions?.clubIncomeTransactionCounts,
  }));
  // console.log(sortCount);
  // console.log('cl', clubIncomeTransactionList);
  const identifier = 'club-income-transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [sort, setSort] = useState('');
  const [selectedClub, setSelectedClub] = useState('');
  const [searchClub, setSearchClub] = useState('');

  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');

  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
  };

  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
  };

  const handleTextFieldChangeClub = (event) => {
    setSearchClub(event.target.value);
  };

  const handleAutocompleteChangeClub = (e, newValue) => {
    // console.log('newValue =>', newValue);
    setSelectedClub(newValue || '');
  };

  // console.log(selectedClub);
  useEffect(() => {
    const memberValues = {
      page: '',
      search: searchClub,
      dispatch,
      limit: '',
    };
    dispatch(getAllClubsAdmin(memberValues));
  }, [dispatch, searchClub]);
  const [tableData, setTableData] = useState([]);
  const totalPage = clubIncomeTransactionsCount;

  useEffect(() => {
    const clubValues = {
      page: '',
      search: searchMember,
      dispatch,
      limit: '',
    };
    dispatch(getAllMembers(clubValues));
  }, [dispatch, searchMember]);

  // console.log(totalPage);

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'description', label: 'Description' },
    { id: 'club', label: 'Club' },
    { id: 'credit', label: 'Credit' },
    { id: 'debit', label: 'Debit' },
  ];
  if (sort === 'MEMBER_CLUB') {
    TABLE_HEAD.splice(2, 0, {
      id: 'member',
      label: 'Member',
    });
  }
  if (sort !== 'MEMBER_CLUB' && sort !== 'CLUB') {
    TABLE_HEAD.splice(2, 0, {
      id: 'category',
      label: 'Category',
    });
  }
  const optionClub = [
    { label: 'All', value: '' },
    ...(clubsAdmin || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  const optionMembers = [
    { label: 'All', value: '' },
    ...(membersList || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  // console.log(membersList);

  // useEffect(() => {
  //   const credentials = {
  //     page: table.page + 1,
  //     limit: storedRowsPerPage || table.rowsPerPage,
  //     sort,
  //     date,
  //     dispatch,
  //   };
  //   dispatch(getAllClubIncomeTransactions(credentials));
  // }, [date, dispatch, searchText, sort, storedRowsPerPage, table.page, table.rowsPerPage]);

  useEffect(() => {
    const incomeTransactionValues = {
      page: table.page + 1,
      dispatch,
      date: formattedDate,
      selectedClub: selectedClub?.value,
      limit: storedRowsPerPage || table.rowsPerPage,
      transactionTypeValues: '',
      selectedMember: selectedMember?.value,
      sort,
    };
    dispatch(getMemberClubIncomeTransactions(incomeTransactionValues));
  }, [
    dispatch,
    formattedDate,
    table.page,
    table.rowsPerPage,
    storedRowsPerPage,
    selectedClub?.value,
    selectedClub,
    selectedMember?.value,
    sort,
  ]);

  // console.log(date);

  useEffect(() => {
    setTableData(clubIncomeTransactions);
  }, [clubIncomeTransactions]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  return (
    <>
      <Stack>
        <Card>
          {/* <Stack p={2} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Club Transactions </Typography>
            <ButtonGroup disableElevation variant="contained" aria-label="Disabled button group">
            <Button
                size="small"
                variant={transactionTypeValues === 'CREDIT' ? 'contained' : 'soft'}
                color="default"
                onClick={() => setTransactionTypeValue('ALL')}
                sx={{ border: 'none' }}
              >
                Credit
              </Button>
              <Button
                size="small"
                variant={transactionTypeValues === 'CREDIT' ? 'contained' : 'soft'}
                color="default"
                onClick={() => setTransactionTypeValue('CREDIT')}
                sx={{ border: 'none' }}
              >
                Credit
              </Button>
              <Button
                color="default"
                variant={transactionTypeValues === 'DEBIT' ? 'contained' : 'soft'}
                size="small"
                onClick={() => setTransactionTypeValue('DEBIT')}
                sx={{ border: 'none' }}
              >
                Debit
              </Button>
            </ButtonGroup>
          </Stack> */}
          <ClubIncomeTransactionSort
            sort={sort}
            setSort={setSort}
            counts={sortCount}
            table={table}
          />

          <Stack mx={2} my={3}>
            <ClubIncomeTransactionToolbar
              date={date}
              setDate={setDate}
              handleTextFieldChangeClub={handleTextFieldChangeClub}
              handleAutocompleteChangeClub={handleAutocompleteChangeClub}
              optionClub={optionClub}
              handleTextFieldChangeMember={handleTextFieldChangeMember}
              handleAutocompleteChangeMember={handleAutocompleteChangeMember}
              optionMembers={optionMembers}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <ClubIncomeTransactionTableRow
                      sort={sort}
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            customRowsPerPage={25}
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

ClubTransactions.propTypes = {
  disable: PropTypes.string,
};
