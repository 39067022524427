import { useState } from 'react';
import PropTypes from 'prop-types';

import { Link, Button, Tooltip, MenuItem, TableRow, TableCell, ListItemText } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { formatTime } from 'src/utils/format-time';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import ScreenshotView from './ScreenshotView';

// ----------------------------------------------------------------------

export default function MemberTableRow({
  sortValue,
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}) {
  const popover = usePopover();
  // const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  // const [date, setDate] = useState();
  // const [text, setText] = useState();
  // let date;
  // let text;

  const {
    _id,
    name,
    createdAt,
    phone,
    email,
    user,
    member_id,
    is_paid,
    is_verified,
    is_blocked,
    blocked_at,
    is_flagged,
    last_flagged_date,
    is_withdrawal_suspended,
    // last_withdrawal_suspended_at,
    current_club,
    verified_at,
    last_withdrawal_suspended_at,
    // is_verified_processed,
    expiry_date,
  } = row;

  // const handleView = (id) => {
  //   navigate(paths.dashboard.course_view(id));
  // };

  const confirm = useBoolean();

  // eslint-disable-next-line consistent-return
  // console.log(last_flagged_date);
  const actionValue = () => {
    switch (sortValue) {
      case 'ALL':
      case 'UNPAID':
      case 'UNVERIFIED':
        return {
          date: createdAt,
          text: 'Joined on',
        };

      case 'VERIFIED':
        return {
          date: verified_at,
          text: 'Verified on',
        };

      case 'EXPIRED':
        return {
          date: expiry_date,
          text: 'Expired on',
        };

      case 'BLOCKED':
        return {
          date: blocked_at,
          text: 'Blocked on',
        };

      case 'FLAGGED':
        return {
          date: last_flagged_date,
          text: 'Flagged on',
        };
      case 'WITHDRAWAL_SUSPENDED':
        return {
          date: last_withdrawal_suspended_at,
          text: 'Suspended on',
        };

      default:
        return {};
      // break;
    }
  };

  const handleStatus = () => {
    if (is_verified === false && is_paid === true) {
      return {
        // label: 'Unverified',
        label: 'Processing',
        color: 'info',
      };
    }
    if (is_paid === false && is_verified === false) {
      return {
        label: 'Unpaid',
        color: 'warning',
      };
    }
    if (is_paid === true && is_verified === true) {
      if (is_blocked && sortValue !== 'FLAGGED' && sortValue !== 'WITHDRAWAL_SUSPENDED') {
        return {
          label: 'Blocked',
          color: 'error',
        };
      }
      if (is_flagged && sortValue !== 'WITHDRAWAL_SUSPENDED') {
        return {
          label: 'Flagged',
          color: 'secondary',
        };
      }
      if (is_withdrawal_suspended) {
        return {
          label: 'Withdrawal Suspended',
          color: 'error',
        };
      }
    }

    return {
      label: 'Verified',
      color: 'success',
    };
  };

  // const handleChangeVerify = () => {
  //   const updateCredential = {
  //     dispatch,
  //     memberId: _id,
  //   };
  //   dispatch(updateMemberStatus(updateCredential));
  // };

  // const showPaymentScreenshotPopup = () => {
  //   setIsOpen(true);
  // };

  // console.log(is_verified_processed);

  return (
    <>
      <ScreenshotView open={isOpen} setOpen={setIsOpen} data={row} />
      <TableRow hover selected={selected}>
        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{createdAt}</TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {member_id}&nbsp;&nbsp;
          {current_club && (
            <Label sx={{ px: 2 }} variant="soft" color="success" size="small">
              <Tooltip title="Club Name">{current_club?.club?.title}</Tooltip>
            </Label>
          )}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {' '}
          <ListItemText
            primary={
              <Link href={`${paths?.dashboard?.member_view(_id)}`} color="#7A4100">
                <Tooltip title="View Member Details">
                  <span>{name}</span>
                </Tooltip>
              </Link>
            }
            secondary={user?.username}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
          {/* <Link href={`${paths?.dashboard?.member_view(_id)}`} color="#7A4100">
            <Tooltip title="View Member Details">{name}</Tooltip>
          </Link> */}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{phone}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label variant="soft" color={handleStatus()?.color || 'error'} size="small">
            {handleStatus()?.label}
          </Label>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <>
            {' '}
            {/* {fDate(actionValue()?.date)} <br /> {actionValue()?.text}{' '} */}
            {formatTime(actionValue()?.date)} <br /> {actionValue()?.text}{' '}
          </>
          {/* actionValue()?.date ??  */}
        </TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'flex-start' }}>
          

          {verified_at && is_verified === true && is_blocked === false && is_flagged === false && is_withdrawal_suspended ===false && (
            <>
              {fDate(verified_at)}
              <br />
              Verified on
            </>
          )}

          {blocked_at && is_verified === true && is_blocked === true && (
            <>
              {fDate(blocked_at)}
              <br />
              Blocked on
            </>
          )}

          {last_flagged_date && is_verified === true && is_flagged === true && (
            <> {fDate(last_flagged_date)} <br /> Flagged on </>
          )}

          {last_withdrawal_suspended_at && is_verified === true && is_withdrawal_suspended === true && (
            <>
              {fDate(last_withdrawal_suspended_at)}
              <br />
              Flagged on
            </>
          )}

          {is_verified === false && is_paid === true && (
            <Stack flexDirection="row" alignItems="start">
              <>
                &nbsp;
                {is_verified_processed === true ? (
                  <Tooltip title="Verification in progress">
                    <Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        // onClick={() => handleChangeVerify()}
                        disabled
                      >
                        Verify
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    color="success"
                    size="small"
                    onClick={() => showPaymentScreenshotPopup()}

                    // onClick={() => handleChangeVerify()}
                  >
                    Verify
                  </Button>
                )}
              </>
            </Stack>
          )}
          {is_paid === false && (
            <>
              {fDate(createdAt)}
              <br />
              Joined on
            </>
          )}
          
        </TableCell> */}

        {/* <Tooltip title="View Payment Screenshot">
          <CardMedia
            onClick={() => showPaymentScreenshotPopup()}
            component="img"
            sx={{
              fontSize: '14px',
              width: 28,
              height: 28,
              lineHeight: 0,
              borderRadius: '50%',
              bgcolor: 'background.neutral',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              objectFit: 'contain',
            }}
            src={verifyImage}
          />
        </Tooltip>  */}

        {/* <TableCell>
          <Link href={`${paths?.dashboard?.member_view(_id)}`}>
            <Tooltip title="View">
              <Iconify icon="solar:eye-bold" sx={{ color: '#00A76F', cursor: 'pointer' }} />
            </Tooltip>
          </Link>
        </TableCell> */}
        {/* {!params?.id && (
          <TableCell align="right" sx={{ px: 1 }}>
            <IconButton color={popover.open ? 'inherit' : 'default'}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )} */}

        {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell> */}
      </TableRow>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

MemberTableRow.propTypes = {
  sortValue: PropTypes.string,
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
