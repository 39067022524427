import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';
import { getMemberDetails, getMemberProfileDetails } from './member';

export const getAllWalletAddress = createAsyncThunk(
  'member/wallet-address/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        // `/member/wallet-address?page=${page && page}&search=${search && search}&limit=${limit || 5}`
        `/wallet-address/member/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createWalletAddress = createAsyncThunk(
  'member/wallet-address/new',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      // const URL = `/member/wallet-address/new `;
      const URL = `/wallet-address/member/new `;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getMemberProfileDetails({
            dispatch,
          })
        );
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getWalletAddressDetails = createAsyncThunk(
  'member/wallet-address/single',
  async ({ walletId, dispatch }) => {
    try {
      // console.log('ID:', walletId);
      const response = await get(`/wallet-address/member/${walletId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateWalletAddressDetails = createAsyncThunk(
  'member/wallet-address/update',
  async ({ state, walletId, dispatch, handleClose, handleClear }) => {
    try {
      // const URL = `/member/wallet-address/${walletId}`;
      const URL = `/wallet-address/member/${walletId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        // dispatch(
        //   getAllSupportLists({
        //     page: 1,
        //     search: '',
        //     dispatch,
        //     limit: sessionStorage.getItem('rowsPerPage_support_list'),
        //     project: '',
        //     date: '',
        //     sort: 'new',
        //   })
        // );
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          dispatch,
        };
        dispatch(getAllWalletAddress(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteWalletAddress = createAsyncThunk(
  'member/wallet-address/delete',
  async ({ walletId, dispatch }) => {
    try {
      // const response = await del(`/member/wallet-address/${walletId}`);
      const response = await del(`/wallet-address/member/${walletId}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address deleted Successfully' }));
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          dispatch,
        };
        dispatch(getAllWalletAddress(credentials));
        dispatch(
          getMemberProfileDetails({
            dispatch,
          })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// admin wallet Address

export const getAllWalletAddressAdmin = createAsyncThunk(
  'admin/wallet-address/list',
  async ({ page, search, limit, dispatch, id }) => {
    try {
      const response = await get(
        // `/member/admin/wallet-address/${id}?page=${page && page}&search=${search && search}&limit=${limit || 5}`
        `/wallet-address/admin/all?member=${id}&page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log('error =>', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createWalletAddressAdmin = createAsyncThunk(
  'admin/wallet-address/new',
  async ({ state, dispatch, handleClose, handleClear, id }) => {
    try {
      // const URL = `/member/admin/wallet-address/new/${id} `;
      const URL = `/wallet-address/admin/new `;
      const response = await post(URL, state);
      if (response) {
        dispatch(
          getMemberDetails({
            memberId: id,
            dispatch,
          })
        );
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          id,
          dispatch,
        };
        dispatch(getAllWalletAddressAdmin(credentials));
        handleClose();
        handleClear();

        dispatch(activeSnack({ type: 'success', message: 'Wallet Address created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getWalletAddressDetailsAdmin = createAsyncThunk(
  'admin/wallet-address/single',
  async ({ walletId, dispatch }) => {
    try {
      // console.log('ID:', walletId);
      // const response = await get(`/member/admin/wallet-address/${walletId}`);
      const response = await get(`/admin/wallet-address/member/${walletId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateWalletAddressDetailsAdmin = createAsyncThunk(
  'admin/wallet-address/update',
  async ({ state, walletId, dispatch, handleClose, handleClear, id }) => {
    try {
      // const URL = `/member/admin/wallet-address/${walletId}/${id}`;
      const URL = `/wallet-address/admin/${walletId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }

        dispatch(activeSnack({ type: 'success', message: 'Wallet Address updated Successfully' }));
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          id: state.member,
          dispatch,
        };
        dispatch(getAllWalletAddressAdmin(credentials));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteWalletAddressAdmin = createAsyncThunk(
  'admin/wallet-address/delete',
  async ({ walletId, id, dispatch }) => {
    try {
      // const response = await del(`/member/admin/wallet-address/${walletId}/${id}`);
      const response = await del(`/wallet-address/admin/${walletId}`);
      if (response) {
        dispatch(
          getMemberDetails({
            memberId: id,
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address deleted Successfully' }));
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          id,
          dispatch,
        };
        dispatch(getAllWalletAddressAdmin(credentials));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
