/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import MemberCards from '../Small.Widgets';

export default function MiniWidgets({ memberDetails }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Grid container pt={isMobile && 1} className="netcard">
      {/* <Grid md={3} xs={6}>
        <MemberCards
          title={memberDetails?.member_id}
          club={memberDetails?.current_club}
          name="memberId"
        />
      </Grid>
      <Grid md={3} pr={2} px={isMobile ? 1 : 2} xs={6}>
        <MemberCards
          title={memberDetails?.is_club_member}
          club={memberDetails?.current_club}
          name="club"
        />
      </Grid>
      <Grid md={3} xs={12} pt={isMobile && 1}>
        <MemberCards
          title={memberDetails?.wallet_balance}
          club={memberDetails?.current_club}
          name="wallet"
        />
      </Grid> */}

      {/* totalearnings */}
      {/* <Grid md={3} xs={12} pt={isMobile && 1}>
        <MemberCards
          title={memberDetails?.earnings_total}
          club={memberDetails?.current_club}
          name="total earnings"
        />
      </Grid>

      <Grid md={3} xs={12} pt={isMobile && 1}>
        <MemberCards
          title={memberDetails?.earnings_total}
          club={memberDetails?.current_club}
          name="TestCard"
        />
      </Grid> */}

      <Grid md={3} xs={12} pt={isMobile && 1} className="netCard1">
        <MemberCards
          title={memberDetails?.earnings_statistics?.net_earnings_total}
          club={memberDetails?.current_club}
          name="Net Earnings"
        />
      </Grid>

      <Grid md={3} xs={12} pt={isMobile && 1} className="netCard2">
        <MemberCards
          title={memberDetails?.earnings_statistics?.catalyst_total}
          club={memberDetails?.current_club}
          name="Catalyst Earnings"
        />
      </Grid>
      <Grid md={3} xs={12} pt={isMobile && 1} className="netCard2">
        <MemberCards
          title={memberDetails?.earnings_statistics?.line_total}
          club={memberDetails?.current_club}
          name="Connect Earnings"
        />
      </Grid>
      <Grid md={3} xs={12} pt={isMobile && 1} className="netCard2">
        <MemberCards
          title={memberDetails?.earnings_statistics?.fourth_total}
          club={memberDetails?.current_club}
          name="Fourth Earnings"
        />
      </Grid>

      <Grid md={3} xs={12} pt={isMobile && 1} className="netCard1">
        <MemberCards
          title={memberDetails?.earnings_statistics?.club_total}
          club={memberDetails?.current_club}
          name="Club Earnings"
        />
      </Grid>

      <Grid md={3} xs={12} pt={isMobile && 1} className="netCard2">
        <MemberCards
          title={memberDetails?.earnings_statistics?.incentive_total}
          club={memberDetails?.current_club}
          name="Incentive Earnings"
        />
      </Grid>
    </Grid>
  );
}
MiniWidgets.propTypes = {
  memberDetails: PropTypes.object,
};
