import PropTypes from 'prop-types';

import { Stack, Button, Typography } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

import { useResponsive } from 'src/hooks/use-responsive';

export default function FilterToolbar({ setCategory, selectedCategory }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Stack direction="row" alignItems="center" justifyContent="start" pl={2} py={2}>
      <Button
        variant="outlined"
        onClick={() => setCategory('')}
        // color={selectedCategory === 'MEMBER' ? 'inherit' : 'secondary'}
        sx={{
          px: 3,
          backgroundColor: selectedCategory === '' ? '#212B36' : '#fff',
          '&:hover': {
            backgroundColor: selectedCategory === '' ? '#212B36' : '#919EAB52',
          },
          color: selectedCategory === '' ? '#fff' : '#000',
        }}
      >
        {/* <BorderAllIcon /> */}
        All
      </Button>
      <Button
        variant="outlined"
        onClick={() => setCategory('MEMBER')}
        // color={selectedCategory === 'MEMBER' ? 'inherit' : 'secondary'}
        sx={{
          px: isMobile ? 1 : 3,
          ml: 1,

          backgroundColor: selectedCategory === 'MEMBER' ? '#212B36' : '#fff',
          '&:hover': {
            backgroundColor: selectedCategory === 'MEMBER' ? '#212B36' : '#919EAB52',
          },
          color: selectedCategory === 'MEMBER' ? '#fff' : '#000',
        }}
      >
        <PeopleAltRoundedIcon />
        &nbsp;<Typography variant={isMobile ? 'caption' : 'subtitle2'}> Member</Typography>
      </Button>
      <Button
        variant="outlined"
        onClick={() => setCategory('COMPANY')}
        // color={selectedCategory === 'COMPANY' ? 'secondary' : 'warning'}
        sx={{
          ml: 1,
          px: 3,
          backgroundColor: selectedCategory === 'COMPANY' ? '#212B36' : '#fff',
          '&:hover': {
            backgroundColor: selectedCategory === 'COMPANY' ? '#212B36' : '#fff',
          },
          color: selectedCategory === 'COMPANY' ? '#fff' : '#000',
        }}
      >
        <ApartmentIcon />
        &nbsp;
        <Typography variant={isMobile ? 'caption' : 'subtitle2'}> Company</Typography>
      </Button>
    </Stack>
  );
}

FilterToolbar.propTypes = {
  setCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};
