/* eslint-disable import/no-unresolved */
import axiosApi from 'axios';

import { paths } from 'src/routes/routes/paths';

// eslint-disable-next-line import/no-cycle
import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

// apply base url for axios
export const TOKEN_PREFIX = 'token';

const env = 'prod';

export const frontEndUrl = 'https://winbuks.io';

export const PRO_BASE_URL = 'https://api.winbuks.io/';
export const DEV_BASE_URL = 'https://api-dev.winbuks.io/';
// export const DEV_BASE_URL = 'http://192.168.1.36:4000/';
// export const DEV_BASE_URL = 'http://192.168.1.87:4000/';

const axios = axiosApi.create({
  baseURL: env === 'prod' ? PRO_BASE_URL : DEV_BASE_URL,
  // baseURL: env === 'prod' ? PRO_BASE_URL : mishab,
});

axios.interceptors.request.use(
  (config) => {
    const { token } = useGetToken(TOKEN_PREFIX);
    const { role } = useGetRoles();

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    const handleDashboardApp = () => {
      switch (role) {
        case 'admin':
          return 'admin';
        case 'member':
          return 'member';
        default:
          return '';
      }
    };

    if (config && config.url && config.url.includes('/admin')) {
      const parts = config.url.split('/admin');
      config.url = `${parts[0]}/${handleDashboardApp()}${parts[1]}`;
    }
    return config;
  },
  (error) => error
);

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    console.error(err.response.status);
    if (err.response.status === 401) {
      sessionStorage.clear('token');
      window.location.replace(paths.public.landing);
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export async function get(url, config = {}) {
  return axios.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axios.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axios.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axios.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return axios.delete(url, { ...config }).then((response) => response.data);
}

// axios.interceptors.request.use(
//   (config) => {
//     const { token } = useGetToken(TOKEN_PREFIX);
//     const { role } = useGetRoles();

//     if (token) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     const handleDashboardApp = () => {
//       switch (role) {
//         case 'admin':
//           return 'admin';
//         case 'partner':
//           return 'partner';
//         case 'school_user':
//           return 'school';
//         default:
//           return '';
//       }
//     };

//     if (config && config.url && config.url.includes("/admin")) {
//       const parts = config.url.split("/admin")
//       config.url = ${parts[0]}/${handleDashboardApp()}${parts[1]}
//     }
//     return config;
//   },

//   (error) => error
// );
