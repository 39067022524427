import { createSlice } from '@reduxjs/toolkit';

import {
  getDashboardData,
  syncDashboardData,
  getIncomeChartData,
  getAllChainNodeData,
  getReferralChartData,
  getProgressionGraphData,
  getSidebarItemCountData,
  getPartnerBalanceChartData,
} from '../api/dashboard';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    error: {},
    loading: false,
    chartLoading: false,
    dashboardData: {},
    referralChart: [],
    incomeChart: [],
    balanceChart: [],
    progressionData: [],
    sidebarCounts: {},
    chain: {},
  },
  reducers: {
    clearDashboardError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllChainNodeData.pending]: (state) => {
      state.loading = true;
    },
    [getAllChainNodeData.fulfilled]: (state, action) => {
      state.loading = false;
      state.chain = action.payload;
      state.error = {};
    },
    [getAllChainNodeData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getSidebarItemCountData.pending]: (state) => {
      state.loading = true;
    },
    [getSidebarItemCountData.fulfilled]: (state, action) => {
      state.loading = false;
      state.sidebarCounts = action.payload;
      state.error = {};
    },
    [getSidebarItemCountData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getDashboardData.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
      state.error = {};
    },
    [getDashboardData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [syncDashboardData.pending]: (state) => {
      state.loading = true;
    },
    [syncDashboardData.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
      state.error = {};
    },
    [syncDashboardData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      console.log('action.error: ', action.error);
    },

    [getReferralChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getReferralChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.referralChart = action.payload;
      state.error = {};
    },
    [getReferralChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },
    [getIncomeChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getIncomeChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.incomeChart = action.payload;
      state.error = {};
    },
    [getIncomeChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getPartnerBalanceChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getPartnerBalanceChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.balanceChart = action.payload;
      state.error = {};
    },
    [getPartnerBalanceChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getProgressionGraphData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getProgressionGraphData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.progressionData = action.payload;
      state.error = {};
    },
    [getProgressionGraphData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },
  },
});
export default dashboardSlice.reducer;
