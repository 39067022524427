/* eslint-disable import/no-unresolved */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { getAllIncentiveLevels } from 'src/server/api/incentive';
// import { getAllTransactionsAdmin } from 'src/server/api/transaction';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import WalletTransferDialog from 'src/sections/income/wallet-Transfer';
import WalletTransferSuccess from 'src/sections/income/WalletTransfer-success-modal';

import BioniumCard from './BioniumCard';
import BioniumTransaction from './BioniumTransactionPage';

// ----------------------------------------------------------------------

export default function BioniumPage() {
  // const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const dispatch = useDispatch();
  // const role = useGetRoles();

  // eslint-disable-next-line no-unused-vars
  const { incentiveLevelList, settingsData, MemberProfileDetails } = useSelector((state) => ({
    loading: state.referrals.loading,
    incentiveLevelList: state.incentive.incentiveLevels?.incentiveLevels,
    MemberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    settingsData: state.settings.settingsDetails,
  }));
  const [openWalletTransferDialog, setOpenWalletTransferDialog] = useState(false);
  const [openWalletTransferSuccess, setOpenWalletTransferSuccess] = useState(false);
  useEffect(() => {
    dispatch(getAllIncentiveLevels(dispatch));
  }, [dispatch]);

  return (
    <>
      {openWalletTransferDialog && (
        <WalletTransferDialog
          setOpen={setOpenWalletTransferDialog}
          open={openWalletTransferDialog}
          setOpenWalletTransferSuccess={setOpenWalletTransferSuccess}
        />
      )}
      {openWalletTransferSuccess && (
        <WalletTransferSuccess
          setOpen={setOpenWalletTransferSuccess}
          open={openWalletTransferSuccess}
        />
      )}
      <CustomBreadcrumbs
        heading="Bionium"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Bionium',
          },
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Stack mt={3} flexDirection="column" spacing={3} width="100%">
        {/* <RenderCountCard isMobile={isMobile} useTheme={useTheme} /> */}
        <BioniumCard settingsData={settingsData} MemberProfileDetails={MemberProfileDetails} />

        <BioniumTransaction />
      </Stack>
    </>
  );
}

// const RenderCountCard = ({ isMobile, useTheme }) => {
//   // eslint-disable-next-line react/no-unstable-nested-components
//   const Completionist = () => <Stack>You are good to go!</Stack>;

//   const countdownKey = 'countdownEndTime';

//   const savedEndTime = localStorage.getItem(countdownKey);

//   const [endTime] = useState(() =>
//     savedEndTime ? parseInt(savedEndTime, 10) : Date.now() + 900000000
//   );

//   useEffect(() => {
//     if (!savedEndTime) {
//       localStorage.setItem(countdownKey, endTime);
//     }
//   }, [endTime, savedEndTime]);
//   const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//       return <Completionist />;
//     }
//     return (
//       <Stack
//         direction="row"
//         alignItems="center"
//         justifyItems="center"
//         spacing={isMobile ? 3 : 4}
//         pl={isMobile && 10}
//       >
//         <Box display="flex">
//           <Typography color="#E6E6E6" variant="h6">
//             {days}
//           </Typography>{' '}
//           &nbsp;
//           <Typography mt={1} variant="caption" color="#E6E6E6" fontWeight="400">
//             DAYS
//           </Typography>
//         </Box>{' '}
//         <Box
//           sx={{
//             height: 25,
//             width: 4,
//             backgroundColor: '#6E6D6D',
//             borderRadius: '50px',
//           }}
//         >
//           {' '}
//         </Box>
//         <Box display="flex">
//           <Typography color="#E6E6E6" variant="h6">
//             {hours}
//           </Typography>
//           &nbsp;
//           <Typography mt={1} variant="caption" color="#E6E6E6" fontWeight="400">
//             HOURS
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             height: 25,
//             width: 4,
//             backgroundColor: '#6E6D6D',
//             borderRadius: '50px',
//           }}
//         >
//           {' '}
//         </Box>{' '}
//         <Box display="flex">
//           <Typography color="#E6E6E6" variant="h6">
//             {minutes}
//           </Typography>
//           &nbsp;
//           <Typography mt={1} variant="caption" color="#E6E6E6" fontWeight="400" pl={0.8}>
//             MINUTES
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             height: 25,
//             width: 4,
//             backgroundColor: '#6E6D6D',
//             borderRadius: '50px',
//           }}
//         >
//           {' '}
//         </Box>{' '}
//         <Box display="flex">
//           <Typography color="#E6E6E6" variant="h6" fontWeight="bold">
//             {seconds}
//           </Typography>
//           &nbsp;
//           <Typography mt={1} variant="caption" color="#E6E6E6" fontWeight="400">
//             SECONDS
//           </Typography>
//         </Box>
//       </Stack>
//     );
//   };
//   return (
//     <Card
//       sx={{
//         borderRadius: 1,
//         p: 2,
//         backgroundColor: '#0D0902',
//         width: 520,
//       }}
//     >
//       <Stack>
//         <Typography color="#E6E6E6">Lounging In</Typography>
//       </Stack>
//       <Countdown date={endTime} renderer={renderer} />
//     </Card>
//   );
// };

// RenderCountCard.propTypes = {
//   isMobile: PropTypes.bool,
//   useTheme: PropTypes.bool,
// };
