/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';

import { Box, Grid, Stack, TextField, Autocomplete } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

export default function BioniumToolbar({
  role,
  memberOption,
  onChange,
  onSearchMember,
  date,
  setDate,
}) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'start' : 'center'}
      spacing={2}
    >
      <Stack direction="row" pl={isMobile && 1}>
        <TextField
          name="date"
          label="Start Date"
          sx={{ width: '100%', minWidth: isMobile && 120, mr: 2 }}
          size="medium"
          type="date"
          variant="outlined"
          inputProps={{ maxLength: 4 }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            setDate({
              ...date,
              from: e.target.value,
            })
          }
        />
        <TextField
          name="date"
          label="End Date"
          sx={{ width: '100%', minWidth: isMobile && 120 }}
          size="medium"
          type="date"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            setDate({
              ...date,
              to: e.target.value,
            })
          }
        />
      </Stack>

      {role === 'admin' && (
        <Grid container>
          <Grid item pl={1} width="25%" xs={6} md={4}>
            <Autocomplete
              id="country-select-demo"
              options={memberOption}
              autoHighlight
              size="medium"
              getOptionLabel={(option) => option.label}
              onChange={onChange}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Members"
                  onChange={onSearchMember}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}

BioniumToolbar.propTypes = {
  onSearch: PropTypes.any,
  date: PropTypes.any,
  setDate: PropTypes.any,
  onSearchMember: PropTypes.string,
  onChange: PropTypes.func,
  memberOption: PropTypes.array,
  role: PropTypes.string,
};
