import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import AddIcon from '@mui/icons-material/Add';
// @mui
import {
  Card,
  Grid,
  Stack,
  Paper,
  Table,
  Button,
  Select,
  MenuItem,
  TableBody,
  TextField,
  InputLabel,
  FormControl,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import user from 'src/assets/member/User.png';
import done from 'src/assets/member/Verified.png';
import userAdd from 'src/assets/member/Adduser.png';
import dislike from 'src/assets/member/Dislike.png';
import { getAllMembers } from 'src/server/api/member';

import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import MemberSort from 'src/sections/Member/MemberSort';
import MemberSearch from 'src/sections/Member/MemberSearch';
// import MemberToolbar from 'src/sections/Member/MemberToolbar';
import MemberTableRow from 'src/sections/Member/MemberTableRow';
// sections
import MemberWidgetSummary from 'src/sections/Member/Small.Widgets';

export default function MemberLists() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');

  const { membersList, loading, membersListCount, membersCount, counts } = useSelector((state) => ({
    membersList: state.member.members?.members,
    membersCount: state.member.members?.total,
    membersListCount: state.member.members,
    counts: state.member.members?.memberSortCounts,
    loading: state.member.loading,
  }));

  // console.log(membersList);

  const [tableData, setTableData] = useState([]);

  const [searchText, setSearchText] = useState('');
  // const [page, setPage] = useState(1);
  // const total = membersListCount?.total;
  const identifier = 'member';

  // console.log(total);
  // const [limit, setLimit] = useState(10);
  const [date, setDate] = useState(10);

  // console.log(tableData);

  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 25 });

  useEffect(() => {
    setTableData(membersList);
  }, [membersList]);
  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const TABLE_HEAD = [
    { id: 'member_code', label: 'Member ID' },
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
  ];

  const handleDeleteClick = (memberDetails) => {
    setTableData(memberDetails);
  };

  const handleEditClick = (memberDetails) => {
    if (memberDetails) {
      setTableData(memberDetails);
    }
  };

  const [sortValue, setSortValue] = useState('ALL');
  const [sortOrder, setSortOrder] = useState('LATEST');
  const [state, setState] = useState({});

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const totalPage = membersCount;
  const limitValue = sessionStorage?.getItem(`rowsPerPage_${identifier}`);

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText,
      dispatch,
      limit: table?.rowsPerPage || limitValue,
      sort: sortValue,
      date,
      sort_order: sortOrder,
    };
    dispatch(getAllMembers(credentials));
  }, [
    dispatch,
    table?.page,
    table?.rowsPerPage,
    limitValue,
    searchText,
    date,
    sortValue,
    sortOrder,
  ]);

  const sortOrders = [
    { label: 'Latest', value: 'LATEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ];

  // const sortOrderOptions = [
  //   { label: 'All', value: '' },
  //   ...(sortOrders || []).map((results) => ({
  //     label: results?.label,
  //     value: results?.value,
  //   })),
  // ];

  const handleChangeSortOrder = (e, newValue) => {
    setSortOrder(e.target.value);
  };

  return (
    <>
      <CustomBreadcrumbs
        heading="Members"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },

          {
            name: 'Members',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Grid container>
        <Grid item md={3} px={1} xs={12}>
          <MemberWidgetSummary
            icon={user}
            title="Total Count"
            total={counts?.allMembersCount || 0}
          />
        </Grid>

        <Grid item md={3} px={1} xs={12} py={isMobile && 1}>
          <MemberWidgetSummary
            icon={userAdd}
            title="Unpaid Count"
            total={counts?.unpaidMembersCount || 0}
          />
        </Grid>
        <Grid item md={3} px={1} xs={12} py={isMobile && 1}>
          <MemberWidgetSummary
            icon={dislike}
            // title="Unverified Count"
            title="Processing Count"
            total={counts?.unverifiedMembersCount || 0}
          />
        </Grid>
        <Grid item md={3} px={1} xs={12}>
          <MemberWidgetSummary
            icon={done}
            title="Verified Count"
            total={counts?.verifiedMembersCount || 0}
          />
        </Grid>
      </Grid>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={3}
        className="member-sort"
      >
        <MemberSort
          sort={sortValue}
          setSort={setSortValue}
          state={state}
          setState={setState}
          memberCount={membersListCount?.memberSortCounts}
        />
      </Stack>

      <Stack
        pb={2}
        spacing={2}
        width="100%"
        alignItems={{ xs: 'start', md: 'center' }}
        justifyContent=""
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Stack direction="row" flex={4} padding="0px 0px 0px 0px">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 160, mr: 2 }}
            size="medium"
            type="date"
            variant="filled"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setDate({
                ...date,
                start_date: e.target.value,
              })
            }
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 165 }}
            size="medium"
            type="date"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setDate({
                ...date,
                end_date: e.target.value,
              })
            }
          />
        </Stack>
        <Stack direction="row" flex={1}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
              labelId="sort-label"
              id="sort-select"
              value={sortOrder}
              onChange={handleChangeSortOrder}
              color="primary"
            >
              {sortOrders.map((item) => (
                <MenuItem value={item?.value}>{item?.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack flex={5}>
          <MemberSearch onSearch={handleSearch} />
        </Stack>
        <Stack flex={1.5}>
          <Button
            component={RouterLink}
            href={paths?.dashboard?.member_create}
            variant="contained"
            // sx={{ width: '110px' }}
            startIcon={<AddIcon sx={{ fontSize: '22px' }} />}
          >
            Member
          </Button>
        </Stack>
      </Stack>

      {loading ? (
        <Paper
          sx={{
            textAlign: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </Paper>
      ) : (
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {(Array.isArray(tableData) ? tableData : []).map((row, index) => (
                    <MemberTableRow
                      sortValue={sortValue}
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={table.rowsPerPage || 5 || limitValue}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onEditRow={() => handleEditClick(row)}
                      onDeleteRow={() => handleDeleteClick(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
            customRowsPerPage={25}
          />
        </Card>
      )}
    </>
  );
}
