import * as Yup from 'yup';
import { parseInt } from 'lodash';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Stack, Button, CardMedia, TextField, Typography, Autocomplete } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

// import { useBoolean } from 'src/hooks/use-boolean';
import useResponsive from 'src/hooks/useResponsive';

import { verifyOtpApi } from 'src/server/api/auth';
import emailSent from 'src/assets/other/ic-email-sent.png';
import { getAllMemberWalletAddress } from 'src/server/api/Wallet';
import { createWithdrawalRequest } from 'src/server/api/withdrawalRequest';

import Iconify from 'src/components/iconify';
import { RHFCode } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

export default function WithdrawalRequestsContent({
  activeStep,
  handleNextStep,
  handleClose,
  setOpenWithdrawalRequestSuccess,
  handlePreviousStep,
  setActiveStep,
}) {
  const isMobile = useResponsive('down', 'md');
  const { enqueueSnackbar } = useSnackbar();

  // const password = useBoolean();
  // const navigate = useNavigate();

  // const { token } = useGetToken(TOKEN_PREFIX);
  // console.log(token);
  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  // const [userNameCheck, setUserNameCheck] = useState('');
  const { loading, MemberProfileDetails, memberWalletAddress, withdrawalRequestLoading } =
    useSelector((state) => ({
      userNamesCheck: state.auth.CheckUserNames,
      user: state.auth.User,
      loading: state.auth.loginLoading,
      paymentLoading: state.auth.loading,
      settingsDetails: state.settings.settingsDetails,
      MemberProfileDetails: state.member.memberProfileDetails.member,
      memberWalletAddress: state.wallet.walletAddress,
      withdrawalRequestLoading: state.withdrawalRequest.loading,
    }));
  const isArrayEmpty =
    !memberWalletAddress?.memberWalletAddresses ||
    memberWalletAddress.memberWalletAddresses.length === 0;
  // const [isNewWallet, setIsNewWallet] = useState(false);

  // const handleCheckboxChange = (event) => {
  //   setIsNewWallet(event.target.checked);
  // };

  //
  const [selectedWalletAddress, setSelectedWalletAddress] = useState('');
  const [searchValueCategory, setSearchValueWalletAddress] = useState('');
  // console.log(user);
  // console.log(settingsDetails);
  // console.log(userNamesCheck);
  // console.log(memberWalletAddress);
  // const router = useRouter();
  const [amount, setAmount] = useState('');

  const dispatch = useDispatch();
  const handleAmountChange = (event) => {
    setAmount(event.target.value); // Update amount state with new value
  };
  const WalletAddressSchema = Yup.object().shape({ wallet_address: Yup.string() });

  const OtpSchema = Yup.object().shape({
    otp: Yup.number().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  });

  const defaultValues = {
    // name: account?.name || '',
    // email: account?.email || '',
    // phone: account?.phone || '',
  };
  // console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(WalletAddressSchema || OtpSchema),
    defaultValues,
  });

  const {
    // setValue,
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  // console.log(selectedWalletAddress);
  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        // ...data,
        // wallet_address_id: selectedWalletAddress || data?.wallet_address,
        wallet_address_id: selectedWalletAddress,
        amount: parseInt(amount),
      },
      dispatch,
      handleClose,
      setOpenWithdrawalRequestSuccess,
      setActiveStep,

      // handleNextStep,
    };
    dispatch(createWithdrawalRequest(credentials));
  });

  const onSubmitOTP = handleSubmit(async (loginFormData) => {
    const otpCrud = {
      state: {
        otp: loginFormData?.otp,
        userId: MemberProfileDetails?.user?._id,
      },
      dispatch,
      enqueueSnackbar,
      handleNextStep,
    };
    dispatch(verifyOtpApi(otpCrud));
    // handleClose();
  });

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 10,
      search: searchValueCategory,
      dispatch,
    };
    // console.log('Credentials:', credentials);
    dispatch(getAllMemberWalletAddress(credentials));
  }, [dispatch, searchValueCategory]);

  const handleAutocompleteChangeWalletAddress = (_, newValue) => {
    setSelectedWalletAddress(newValue?.value || '');
    console.log('newValue: ', newValue);
  };
  const handleTextFieldChangeWalletAddress = (event) => {
    setSearchValueWalletAddress(event.target.value);
    console.log('event.target.value: ', event.target.value);
  };
  const optionWalletAddress = [
    ...(memberWalletAddress?.memberWalletAddresses || []).map((item) => ({
      label: `${item?.title ? `${item?.title} : ` : ''} ${item?.wallet_id}`,
      value: item?._id,
    })),
  ];
  const renderFormOtp = (
    <>
      <Stack p={3}>
        <Stack flexDirection="row" mt={1} alignItems="center">
          <Iconify
            width={15}
            icon="eva:info-fill"
            sx={{
              mr: 1,
              color: '#637381',
            }}
          />
          <Typography variant="caption" color="#637381">
            10% will be deducted for line distribution and balance will be transferred to your
            selected wallet address.{' '}
          </Typography>
        </Stack>
        <Stack flexDirection="row" mt={4} mb={2} alignItems="center">
          <CardMedia
            component="img"
            src={emailSent}
            alt="qr-code"
            sx={{ width: '13.6px', mr: 1 }}
          />
          <Typography variant="caption" color="#637381">
            An OTP will be sent to the email you provided. enter the OTP on the below box to
            complete the withdrawal request process.
          </Typography>
        </Stack>
        <RHFCode name="otp" mb={3} />
      </Stack>
      <Stack
        p={3}
        flexDirection="row"
        gap={2}
        justifyContent="end"
        sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
      >
        <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          fullWidth
          size="small"
          type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
          loading={isSubmitting || loading}
        >
          Verify
        </LoadingButton>
      </Stack>
    </>
  );
  const FitContentButton = styled(Button)({
    width: 'fit-content',
    whiteSpace: 'nowrap', // Ensures the button does not break the text into multiple lines
  });
  const renderWallet = (
    <>
      <Stack p={3} spacing={2}>
        {/* {isNewWallet ? (
          <RHFTextField
            sx={{ width: '100%' }}
            name="wallet_address"
            required
            label="Wallet Id"
            placeholder="eg: 1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71"
            InputLabelProps
          />
        ) : ( */}
        {isArrayEmpty ? (
          <Stack justifyContent="center" alignItems="center">
            <Stack alignItems="center" flexDirection="row" justifyContent="center" mb={2}>
              <ErrorOutlineIcon sx={{ marginRight: '5px', color: '#697077', fontSize: '15px' }} />
              <Typography
                variant="caption"
                color="#697077"
                justifyContent="center"
                alignItems="center"
                mt={0.2}
              >
                No wallet Address Available{' '}
              </Typography>
            </Stack>
            <FitContentButton
              variant="outlined"
              width="fi"
              component={Link}
              to={paths?.dashboard?.profile}
            >
              New Wallet Address
            </FitContentButton>
          </Stack>
        ) : (
          <Autocomplete
            id="WalletAddress-select-demo"
            sx={{ width: '100%' }}
            options={optionWalletAddress}
            autoHighlight
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={handleAutocompleteChangeWalletAddress}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(param) => (
              <TextField
                {...param}
                label="Wallet"
                required
                onChange={handleTextFieldChangeWalletAddress}
                inputProps={{
                  ...param.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        )}

        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ color: '#00A76F' }}
            checked={isNewWallet}
            onChange={handleCheckboxChange}
          />{' '}
          <Typography variant="body2">Can&apos;t locate your wallet? Make a new one.</Typography>
        </Box> */}
      </Stack>
      <Stack
        p={3}
        flexDirection="row"
        gap={2}
        justifyContent="end"
        sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
      >
        <Button
          startIcon={<ArrowBackIosIcon style={{ fontSize: '14px' }} />}
          variant="outlined"
          sx={{ height: '28px' }}
          onClick={handlePreviousStep}
        >
          Previous
        </Button>

        <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          fullWidth
          size="small"
          type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
          loading={isSubmitting || withdrawalRequestLoading}
        >
          Continue
        </LoadingButton>
      </Stack>
    </>
  );
  return (
    <>
      {activeStep === 1 && (
        <Stack mt={2}>
          {/* <FormProvider methods={methods} onSubmit={onSubmit}> */}
          <Stack p={3}>
            <TextField
              sx={{ width: '100%' }}
              name="amount"
              required
              label="Amount"
              type="number"
              placeholder="Enter Amount.."
              InputLabelProps
              onChange={handleAmountChange}
            />
            <Stack flexDirection="row" mt={2}>
              <Iconify
                width={15}
                icon="eva:info-fill"
                sx={{
                  mr: 0.5,
                  color: '#637381',
                }}
              />
              <Typography variant="caption" color="#637381">
                You can withdraw minimum of 10 USDT and a maximum amount equal to your wallet
                balance.{' '}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            p={3}
            flexDirection="row"
            gap={2}
            justifyContent="end"
            sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
          >
            <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'success.main',
                width: '80px',
                height: '28px',
                '&:hover': { backgroundColor: '#047f56' },
              }}
              onClick={handleNextStep}
            >
              Continue
            </Button>
          </Stack>
          {/* </FormProvider> */}
        </Stack>
      )}
      {activeStep === 2 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderWallet}
          </FormProvider>
        </Stack>
      )}
      {activeStep === 0 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmitOTP}>
            {renderFormOtp}
          </FormProvider>
        </Stack>
      )}
    </>
  );
}

WithdrawalRequestsContent.propTypes = {
  activeStep: PropTypes.any,
  handleNextStep: PropTypes.any,
  handleClose: PropTypes.any,
  setOpenWithdrawalRequestSuccess: PropTypes.func,
  handlePreviousStep: PropTypes.any,
  setActiveStep: PropTypes.any,
};
