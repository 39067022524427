import PropTypes from 'prop-types';

import { Paper, Stack, Typography } from '@mui/material';

import { fDateTime } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function VerifiedDetails({ memberDetails }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px dashed #ccee',
        py: 3.5,
        borderRadius: 3,

        px: 2,
      }}
    >
      <Stack flexDirection="" alignItems="start" justifyContent="start">
        <Typography sx={{ color: '#00A76F', fontSize: 12 }}>Verified Date</Typography>
        <Typography sx={{ fontSize: 14 }}>{fDateTime(memberDetails?.verified_at)}</Typography>
      </Stack>
      {/* <Stack flexDirection="" alignItems="start" justifyContent="start" mt={2}>
        <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>Joined on</Typography>
        <Typography sx={{ fontSize: 14, }}>
          {fDateTime(memberDetails?.createdAt)}
        </Typography>
      </Stack> */}
      {memberDetails?.wallet_address_last_updated_at && (
        <Stack flexDirection="" alignItems="start" justifyContent="start" mt={2}>
          <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>
            Wallet Address last changed
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            {fDateTime(memberDetails?.wallet_address_last_updated_at)}
          </Typography>
        </Stack>
      )}
    </Paper>
  );
}
VerifiedDetails.propTypes = {
  memberDetails: PropTypes.object,
};
